<template>
  <div class="header_area headerTop" >
    <div class="header_bottom">
      <div class="container">
        <div class="d-flex justify-content-between align-items-center" v-if="device === 'desktop'">
          <div class="header_bottom_right d-flex align-items-center" >
            <div class="btnarea d-flex align-items-center">
              <a :href="homeLink" class="btn-two" v-if="marketerId !== '' || currentPage !== 'home'"> {{ $t("top-nav.domestic-tourism") }}</a>
              <a :href="fellowLink" class="btn-one" v-if="showFellowLink">{{ $t("top-nav.vacation-country") }}</a>
            </div>
          </div>
          <div class="logobox" >
            <a :href="logoURL"><img :src="srcLogo" alt="logo"></a>
          </div>
        </div>
        <div class="d-flex justify-content-between align-items-center" v-else-if="device === 'mobile'">
          <div class="header_bottom_right d-flex align-items-center" >
            <div class="btnarea d-flex align-items-center">
              <button class="button-nav-toggle" v-b-toggle.nav-menu-links>
                <img class="one" src="assets/img/menu.png" alt="menu">
                <img class="two" src="assets/img/menu1.png" alt="menu1">
              </button>
              <b-sidebar id="nav-menu-links" right shadow no-header backdrop>
                <ul class="navbar-nav">
                  <li class="nav-item active">
                    <a :href="`tel:${phoneNumber}`" v-if="marketerId !== ''"> {{ phoneNumber }}: {{ $t("top-header.vacation-in-israel") }}</a>
                  </li>
                  <li class="nav-item">
                    <a :href="homeLink" v-if="marketerId !== '' || currentPage !== 'home'"> {{ lang==='he' ? $t("top-nav.domestic-tourism") : $t("top-nav.domestic-tourism-short") }}</a>
                  </li>
                  <li class="nav-item">
                    <a :href="fellowLink" v-if="showFellowLink">{{ lang==='he' ? $t("top-nav.vacation-country") : $t("top-nav.vacation-country-short") }}</a>
                  </li>
                </ul>
              </b-sidebar>
            </div>
          </div>
          <div class="logobox" :class="device==='mobile' && 'ml-4'">
              <a :href="logoURL"><img :src="srcLogo" alt="logo"></a>
            </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { VBToggle, BSidebar } from 'bootstrap-vue';
import TemplateTopNav from './TemplateTopNav';

export default {
  name: 'TopNavTheme1',
  mixins: [TemplateTopNav],
  components: {
    BSidebar,
  },
  directives: {
    'b-toggle': VBToggle,
  },
};
</script>

<style scoped>
.headerTop.header_area .header_bottom {
    position: absolute;
    width: 100%;
    z-index: 100;
    padding: 24px 0px;
}
.headerTop.header_area .header_bottom .logobox img{
    max-width: 215px;
    max-height: 50px;
}
.headerTop.header_area .button-nav-toggle {
  font-size: 1.3em;
  float: right;
  border: 0;
  background: 0 0;
  color: #000;
  outline: 0;
  cursor: pointer;
  padding: 0;
    height: 44px;
    display: flex;
}

.headerTop.header_area .main.open .button-nav-toggle img.two{ display:block; object-fit:contain; width:100%; }
.headerTop.header_area .main.open .button-nav-toggle img.one{ display:none; object-fit:contain; width:100%; }
.headerTop.header_area .button-nav-toggle img.two{ display:none; }
.headerTop.header_area .button-nav-toggle img{object-fit:contain; width:100%; height: 100%;}
.headerTop.header_area .nav-main {
  transition: right .25s ease-in-out;
  background: #ffffff;
  position: fixed;
  overflow-x: hidden;
  width: 280px;
  right: -280px;
  top: 0;
  bottom: 0
}

.headerTop.header_area .btnarea .btn-one {
    background-color: #23BEDC;
    color: #fff;
    font-weight: 400;
    margin-right: 14px;
    padding: 8px 18px;
    border-radius:5px;
}
.headerTop.header_area .btnarea .btn-one:hover{
    background-color:#000;
    color: #fff;
}
.headerTop.header_area .btnarea .btn-two {
    background-color: #fff;
    color: #23BEDC;
    font-weight: 400;
    margin-right: 14px;
    padding: 8px 18px;
    border-radius: 5px;
}
.headerTop.header_area .btnarea .btn-two:hover{
   background-color: #000;
   color: #fff;
}

.headerTop.header_area ul.navbar-nav {
  margin: 0;
  padding: 0 20px
}

.headerTop.header_area ul.navbar-nav li {
  list-style: none;
  border-bottom: 1px solid #d5d7da
}

.headerTop.header_area ul.navbar-nav li a {
  text-decoration: none;
  display: block;
  padding: 20px 0;
  color: #4d5152;
  font-size: 16px
}

.headerTop.header_area ul.navbar-nav li a:hover { color: #4d5152 }

.headerTop.header_area ul.navbar-nav li ul {
  display: none;
  width: 280px;
  position: absolute;
  right: -280px;
  top: 0
}

.headerTop.header_area ul.navbar-nav li ul li { border: 0 }

.headerTop.header_area ul.navbar-nav li ul li.nav-label {
  border-top: 1px solid #d5d7da;
  padding: 25px 0 10px
}

.headerTop.header_area ul.navbar-nav li ul li a {
  padding: 7px 0 15px;
  font-size: 14px
}

.headerTop.header_area ul.navbar-nav li ul li a.back {
  font-size: 16px;
  font-weight: 700;
  text-transform: uppercase;
  padding: 20px 25px;
  background: url(/assets/img/theme1/arrow-left.png) left center no-repeat
}

.headerTop.header_area ul.navbar-nav li.has-sub-nav a {
    background: url(/assets/img/theme1/arrow-left.png) left center no-repeat;
}
.headerTop.header_area ul.navbar-nav li.has-sub-nav ul a { background: 0 0 }

.headerTop.header_area ul.navbar-nav li.active ul { display: block }

@media (max-width:991.98px){
  .headerTop.header_area .header_bottom {
      position: relative;
      padding: 10px 0px;
      background-color: #1993c8;
  }
}

@media (max-width:767.98px){
  .header_area .header_bottom .logobox img {
      max-width: 170px;
  }
  .header_area .btnarea .btn-one {
      margin-right: 10px;
      padding: 8px 15px;
      border-radius: 5px;
      font-size: 12px;
  }
  .header_area .btnarea .btn-two {
      margin-right: 10px;
      padding: 8px 15px;
      border-radius: 5px;
      font-size: 12px;
  }
}

@media (max-width:639.98px){
  .header_area .header_bottom .logobox img {
      max-width: 146px;
  }
  .header_area ul.navbar-nav li a {
      padding: 14px 0;
      font-size: 14px;
  }
}

@media (max-width:479.98px){
  .headerTop.header_area .btnarea .btn-one {
      display: none;
  }
  .headerTop.header_area .btnarea .btn-two{
      display: none;
  }
}
</style>
